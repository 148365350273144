<!-- 开店完善信息页面 -->
<template>
  <div class="width-all"
       id="supplierEditStoreInfo"
       @scroll.navite="mainScrollEvent">
    <template v-if="data.reviewStatus <= 1">
      <el-form :model="data"
               :ref="formNameEditStore"
               v-loading="loading"
               label-width="160px"
               @submit.native.prevent>
        <div class="edit-module flex-item edit-store-main-title">
          <img class="left logo"
               :src="userInfo.logo"
               alt="logo">
          <div class="content title">{{PROJECT_NAME}}店铺入驻</div>
          <el-button class="right"
                     size="mine"
                     @click="goBack">跳过</el-button>
        </div>
        <!-- 基础信息 -->
        <div class="edit-module"
             v-show="false">
          <!-- <div class="edit-header">
            <div class="left title">基础信息</div>
            <div class="content"></div>
          </div> -->
          <div class="padding-right-map map-left-width">
            <el-form-item label="账号联系人"
                          class="form-item max-width"
                          prop="checkData.contactPerson"
                          :rules="formRules.required">
              <el-input v-model="data.checkData.contactPerson"
                        placeholder="请填该账号运营者名称"></el-input>
            </el-form-item>
          </div>
        </div>
        <!-- 店铺信息 -->
        <!-- <div class="edit-module">
          <div class="edit-header">
            <div class="left title">店铺信息</div>
            <div class="content"></div>
          </div>
          <div class="flex-item align-items-end">
            <div class="content map-left-width">
              <el-form-item class="form-item "
                            label="店铺名称："
                            prop="checkData.storeJson.name"
                            :rules="formRules.name">
                <el-input v-model="data.checkData.storeJson.name"></el-input>
              </el-form-item>
              <el-form-item class="form-item "
                            label="店铺简介："
                            prop="checkData.storeJson.brief"
                            :rules="formRules.required">
                <el-input v-model="data.checkData.storeJson.brief"></el-input>
              </el-form-item>
              <el-form-item class="form-item "
                            label="店铺logo："
                            prop="checkData.storeJson.logo"
                            :rules="formRules.required">
                <upload-images-one :file.sync="data.checkData.storeJson.logo"
                                   width="100px"
                                   :show-hint-text="true"
                                   :files-size="100"
                                   files-size-unit="kb"></upload-images-one>
              </el-form-item>
              <el-form-item class="form-item "
                            label="所在区域："
                            prop="checkData.storeJson.townCode"
                            :rules="formRules.required">
                <el-cascader class="width-all"
                             ref="region"
                             v-model="regionValue"
                             :props="{lazy: true,value:'id',label:'name',lazyLoad}" @change="regionChange"
                             v-if="showRegion"></el-cascader>
              </el-form-item>
              <el-form-item class="form-item"
                            label="详细地址"
                            prop="checkData.storeJson.address"
                            :rules="formRules.required">
                <el-input v-model="data.checkData.storeJson.address"
                          @blur="getAddressLatLng"
                          @keyup.enter.native="getAddressLatLng">
                </el-input>
                <div class="color-info margin-top-10"
                     style="line-height: initial;"
                     v-if="data.checkData.storeJson.addressInfo">{{data.checkData.storeJson.addressInfo}}</div>
              </el-form-item>
              <el-form-item class="form-item "
                            label="经纬度："
                            prop="checkData.storeJson.latitude"
                            :rules="formRules.required">
                <div class="">
                  [{{data.checkData.storeJson.latitude}},{{data.checkData.storeJson.longitude}}]
                  <el-button class="margin-left"
                             type="primary"
                             size="mini"
                             icon="el-icon-map-location"
                             @click="getAddressLatLng" >定位</el-button>
                </div>
                <el-alert class="margin-top-10"
                          type="info"
                          style="line-height: initial;">
                  <span class="" slot="title">
                    1、选择“所在区域”。<br>
                    2、填写“详细地址”。 <br>
                    3、按“回车键”或者点击“定位”。
                  </span>
                </el-alert>
              </el-form-item>

            </div>
            <div class="right margin-bottom">
              <div class="map-width" id="mapBox" style="height:350px;"></div>
            </div>
          </div>
        </div> -->

        <div class="edit-module">
          <div class="edit-header">
            <div class="left title">主体信息</div>
            <div class="content"></div>
          </div>
          <div class="padding-right-map">
            <el-form-item label="主体类型："
                          class="form-item max-width"
                          prop="checkData.qualificationJson.companyType"
                          :rules="formRules.required">
              <el-radio-group v-model="data.checkData.qualificationJson.companyType"
                              @change="changeCompanyType">
                <el-radio :label="item.id"
                          v-for="(item, index) in companyTypeArray"
                          :key="index">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="营业执照类型："
                          class="form-item max-width"
                          prop="checkData.qualificationJson.businessLicenseType"
                          :rules="formRules.required">
              <el-radio-group v-model="data.checkData.qualificationJson.businessLicenseType"
                              @change="changebusinessLicenseType">
                <el-radio :label="item.id"
                          v-for="(item, index) in businessLicenseTypeArray"
                          :key="index">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="主体名称："
                          class="form-item max-width"
                          prop="checkData.qualificationJson.companyName"
                          :rules="formRules.required">
              <el-input v-model="data.checkData.qualificationJson.companyName"
                        placeholder="公司注册名称"
                        @blur="blurContactPerson"></el-input>
            </el-form-item>

             <el-form-item label="经营简称："
                          class="form-item max-width"
                          prop="checkData.qualificationJson.shortName"
                          :rules="formRules.shortName">
              <el-input v-model="data.checkData.qualificationJson.shortName"
                        placeholder="经营简称"
                        ></el-input>
            </el-form-item>

            <el-form-item label="营业执照编号："
                          class="form-item max-width"
                          prop="checkData.qualificationJson.businessLicenseNo"
                          :rules="formRules.required">
              <el-input v-model="data.checkData.qualificationJson.businessLicenseNo"
                        placeholder="营业执照证书编号"></el-input>
            </el-form-item>
            <el-form-item class="form-item"
                          label="营业执照："
                          prop="checkData.qualificationJson.businessLicenseImg"
                          :rules="formRules.required">
              <upload-images-one :file.sync="data.checkData.qualificationJson.businessLicenseImg"
                                 width="100px"
                                 :show-hint-text="true"
                                 :files-size="1"></upload-images-one>
            </el-form-item>

            <el-form-item
              label="营业期限"
              class="form-item"
              :rules="formRules.required"
              prop="checkData.qualificationJson.businessHoursStart"
            >
              <el-date-picker
                v-model="data.checkData.qualificationJson.businessHoursStart"
                type="date"
                value-format="timestamp"
                placeholder="开始日期">
              </el-date-picker>
              <span style="margin: 0 10px;">至</span>
              <el-date-picker
                v-model="data.checkData.qualificationJson.businessHoursEnd"
                type="date"
                value-format="timestamp"
                :disabled="noBusinessHoursEnd"
                @change="dateChange"
                :placeholder="endData">
              </el-date-picker>
              <el-checkbox style="margin-left: 20px;" @change="radioChange"  v-model="noBusinessHoursEnd">长期</el-checkbox>
            </el-form-item>
            <el-form-item
                label="注册地址"
                :rules="formRules.required"
                class="form-item"
                prop="checkData.qualificationJson.businessCountyId"
              >
                <el-cascader
                  class="width-all"
                  ref="businessAddress"
                  v-model="data.checkData.qualificationJson.businessCountyId"
                  :props="{ lazy: true, value: 'id', label: 'name', emitPath:false, lazyLoad: lazyLoad3 }"
                  @change="regionYYChange"
                ></el-cascader>
            </el-form-item>
            <el-form-item
              label="注册详细地址"
              class="form-item"
              :rules="formRules.address"
              prop="checkData.qualificationJson.registeredAddress"
            >
              <el-input v-model="data.checkData.qualificationJson.registeredAddress" @input="change($event)"></el-input>
            </el-form-item>

            <el-form-item v-if="data.checkData.qualificationJson.businessLicenseType != 2"
                          class="form-item"
                          label="组织机构代码证"
                          prop="checkData.qualificationJson.circulationPermitImg">
              <upload-images-one :file.sync="data.checkData.qualificationJson.circulationPermitImg"
                                 width="100px"
                                 :show-hint-text="true"
                                 :files-size="1"></upload-images-one>
            </el-form-item>
            <el-form-item label="纳税人类型："
                          class="form-item max-width"
                          prop="checkData.qualificationJson.taxpayerType"
                          :rules="formRules.required">
              <el-radio-group v-model="data.checkData.qualificationJson.taxpayerType">
                <el-radio :label="item.id"
                          v-for="item in taxpayerTypeArray">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="form-item"
                          label="一般纳税人证明材料："
                          prop="checkData.qualificationJson.normalTaxpayerImg"
                          :rules="formRules.required"
                          v-if="data.checkData.qualificationJson.taxpayerType === 2">
              <upload-images-one :file.sync="data.checkData.qualificationJson.normalTaxpayerImg"
                                 width="100px"
                                 :show-hint-text="true"
                                 :files-size="1"></upload-images-one>
            </el-form-item>
          </div>
        </div>
        <div class="edit-module">
          <div class="edit-header">
            <div class="left title">{{operatorName}}信息</div>
            <div class="content"></div>
          </div>
          <div class="padding-right-map map-left-width">
            <el-form-item :label="operatorName + '姓名：'"
                          class="form-item max-width"
                          prop="checkData.qualificationJson.operatorsName"
                          :rules="formRules.required">
              <el-input v-model="data.checkData.qualificationJson.operatorsName"
                        placeholder="请输入姓名"
                        @blur="blurOperatorsName"></el-input>
            </el-form-item>
            <el-form-item :label="operatorName + '身份证号：'"
                          class="form-item max-width"
                          prop="checkData.qualificationJson.operatorsCardNo"
                          :rules="formRules.IDNo">
              <el-input v-model="data.checkData.qualificationJson.operatorsCardNo"
                        placeholder="请输入18位身份证号"></el-input>
            </el-form-item>
            <div class="flex-item align-items-start">
              <div class="form-item-label text-right"
                   style="min-width: 160px;">
                {{operatorName}}身份证图片：
              </div>
              <el-form-item label="正面"
                            label-width="0"
                            class="form-item max-width left form-item-label-row"
                            prop="checkData.qualificationJson.operatorsCardPositiveImg"
                            :rules="formRules.required">
                <upload-images-one class="left"
                                   :file.sync="data.checkData.qualificationJson.operatorsCardPositiveImg"
                                   width="100px"
                                   :show-hint-text="true"
                                   :files-size="1"></upload-images-one>
              </el-form-item>
              <el-form-item label="反面"
                            label-width="0"
                            class="form-item max-width left form-item-label-row"
                            prop="checkData.qualificationJson.operatorsCardBackImg"
                            :rules="formRules.required">
                <upload-images-one class="left"
                                   :file.sync="data.checkData.qualificationJson.operatorsCardBackImg"
                                   width="100px"
                                   :show-hint-text="true"
                                   :files-size="1"></upload-images-one>
              </el-form-item>
            </div>


            <el-form-item label="证件有效期" class="form-item" required>
              <el-date-picker
                v-model="data.checkData.qualificationJson.personHoursStart"
                type="date"
                value-format="timestamp"
                placeholder="开始时间"
              >
              </el-date-picker>
              <span style="margin: 0 10px">至</span>
              <el-date-picker
                v-model="data.checkData.qualificationJson.personHoursEnd"
                type="date"
                :placeholder="noPersonHoursEnd ? '长期' : '结束时间'"
                value-format="timestamp"
                :disabled="noPersonHoursEnd"
              >
              </el-date-picker>
              <el-checkbox
                style="margin-left: 20px"
                @change="radioChangePerson"
                v-model="noPersonHoursEnd"
                >长期</el-checkbox
              >
            </el-form-item>

            <el-form-item :label="operatorName + '联系方式：'"
                          class="form-item max-width"
                          prop="checkData.qualificationJson.operatorsPhone"
                          :rules="formRules.phone">
              <el-input v-model="data.checkData.qualificationJson.operatorsPhone"
                        placeholder=""></el-input>
            </el-form-item>
            <el-form-item :label="operatorName + '邮箱：'"
                          class="form-item max-width"
                          prop="checkData.qualificationJson.operatorsEmail"
                          :rules="formRules.email">
              <el-input v-model="data.checkData.qualificationJson.operatorsEmail"
                        placeholder="xxxx@xx.xxx"></el-input>
            </el-form-item>
          </div>
        </div>

        <div class="edit-module">
          <div class="edit-header">
            <div class="left title">银行结算信息</div>
            <div class="content"></div>
          </div>
          <div class="padding-right-map map-left-width">
            <el-form-item label="账户类型："
                          class="form-item max-width"
                          prop="checkData.settlementJson.bankType"
                          :rules="formRules.required">
              <el-radio-group v-model="data.checkData.settlementJson.bankType"
                              @change="changeBankType">
                <el-radio :label="item.id"
                          v-for="item in bankTypeArray"
                          :disabled="item.id === 'public'? false : data.checkData.qualificationJson.companyType === 2">{{item.name}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="form-item"
                          label="开户许可证："
                          prop="checkData.settlementJson.openPermitImg"
                          :rules="formRules.required"
                          v-if="data.checkData.settlementJson.bankType === 'public'">
              <upload-images-one :file.sync="data.checkData.settlementJson.openPermitImg"
                                 width="100px"
                                 :show-hint-text="true"
                                 :files-size="1"></upload-images-one>
            </el-form-item>
            <el-form-item label="开户行" class="form-item" prop="checkData.settlementJson.bankName" :rules="formRules.required">
              <el-select
                width="400"
                v-model="data.checkData.settlementJson.bankCode"
                filterable
                remote
                placeholder="请输入关键词"
                @change="bankChange"
                :remote-method="getBankNumberList"
                :loading="bankLoading">
                <el-option
                  v-for="item in bankList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <div class="color-info">如：中国银行</div>
            </el-form-item>
            <el-form-item
              label="开户行地区"
              :rules="formRules.required"
              class="form-item"
              prop="checkData.settlementJson.bankCityId"
            >
              <el-cascader
                class="width-all"
                ref="bankAddress"
                v-model="data.checkData.bankCityId"
                :props="{ lazy: true, value: 'id', label: 'name', emitPath:false, lazyLoad: lazyLoad2}"
                @change="regionBankChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="开户支行" class="form-item" prop="checkData.settlementJson.bankBranch" :rules="formRules.required">
              <el-select
                v-model="data.checkData.settlementJson.bankBranchCode"
                filterable
                remote
                placeholder="请输入关键词"
                @change="bankBranchChange"
                :remote-method="getBankBranchNumberList"
                :loading="bankLoading">
                <el-option
                  v-for="item in bankBranchList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <div class="color-info">如：深圳西乡支行</div>
            </el-form-item>
            <el-form-item label="开户名称："
                          class="form-item max-width"
                          prop="checkData.settlementJson.bankHolderName"
                          :rules="formRules.required">
              <el-input v-model="data.checkData.settlementJson.bankHolderName"
                        placeholder="开户人(主体)名称（例：陈某某）"></el-input>
              <el-alert class="margin-top-10"
                        type="info"
                        style="line-height: initial;"
                        v-if="data.checkData.qualificationJson.companyType === 2">
                <span class=""
                      slot="title">
                  主体类型为“公司/企业”时,“银行开户名”须与“主体名称”一致。
                </span>
              </el-alert>
            </el-form-item>

            <el-form-item label="银行卡号："
                          class="form-item max-width"
                          prop="checkData.settlementJson.bankCard"
                          :rules="formRules.backNo">
              <div class="back-card-wrap">
                <el-input v-model="data.checkData.settlementJson.bankCard"
                          placeholder="开户银行支行名称（例：深圳西乡支行）"
                          maxlength="24"
                          @focus="bankCardFocus=true"
                          @blur="bankCardFocus=false">
                  <!--<span slot="append">{{data.checkData.settlementJson.bankCard.length}}</span>-->
                </el-input>
                <div class="back-card-no"
                     :class="{cursor:bankCardFocus}">{{bankCardNo}}</div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <div class="edit-module">
        <div class="padding-right-map map-left-width text-center">
          <el-button class=""
                     type="warning"
                     @click="pushTestData"
                     v-if="$store.state.isTest">
            <el-icon class="el-icon-warning margin-right-10" />测试数据
          </el-button>
          <el-button class=""
                     type=""
                     @click="submitFormDraft">
            <el-icon class="el-icon-edit-outline margin-right-10" />保存草稿
          </el-button>
          <el-button class=""
                     type="primary"
                     @click="submitForm">
            <el-icon class="el-icon-edit-outline margin-right-10" />提交审核
          </el-button>
        </div>
      </div>

      <div class="fixed-action-right-bottom edit-store-action">
        <el-button type=""
                   @click="goBackTop"
                   v-if="showBackTop">
          <el-icon class="el-icon-top" />返回顶部
        </el-button>
        <el-button type=""
                   @click="goBack">
          <el-icon class="el-icon-back" />返回上页
        </el-button>
        <el-button class=""
                   type="warning"
                   @click="pushTestData"
                   v-if="$store.state.isTest">
          <el-icon class="el-icon-warning" />测试数据
        </el-button>
        <el-button type=""
                   @click="submitFormDraft">
          <el-icon class="el-icon-edit-outline" />保存草稿
        </el-button>
        <el-button type="primary"
                   @click="submitForm">
          <el-icon class="el-icon-edit-outline" />提交审核
        </el-button>
      </div>
    </template>
    <template v-else-if="data.reviewStatus === 2">
      <div class="edit-module review-status-box">
        <i class="el-icon el-icon-time"></i>
        <h3>审核中...</h3>
        <p>请耐心等待，将在3个工作日内完成，届时招商经理可能与您联系，请保持电话畅通。</p>
        <el-button icon="el-icon-back"
                   @click="goHome()">返回首页</el-button>
      </div>
    </template>
    <template v-else-if="data.reviewStatus === 3">
      <div class="edit-module review-status-box">
        <i class="el-icon el-icon-success color-primary"></i>
        <h3>审核通过...</h3>
        <p>您的资料已经审核通过。</p>
        <el-button icon="el-icon-back"
                   @click="goHome()">返回首页</el-button>
      </div>
    </template>
    <template v-else-if="data.reviewStatus === 4">
      <div class="edit-module review-status-box">
        <i class="el-icon el-icon-close color-danger"></i>
        <h3>审核失败...</h3>
        <p>您提交的资料审核已被拒绝。</p>
        <div class="">
          <el-button icon="el-icon-back"
                     @click="goHome()">返回首页</el-button>
          <el-button icon="el-icon-edit"
                     @click="againEdit()"
                     type="danger">重新提交</el-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { returnBaseDataStoreInfo, returnBaseDataStoreInfoTestData } from '../../../assets/js/modules/storeInfo/storeInfo'
import * as formRules from '../../../assets/js/formRules'
import { PROJECT_NAME, URL } from '../../../config'
import uploadImagesOne from '../../../components/public/upload/uploadImagesOne'

import { isEmpty, goBack, goHome } from '../../../assets/js/utils'
import { companyType, companyTypeArray, taxpayerType, taxpayerTypeArray, businessLicenseTypeArray, bankTypeArray } from '../../../assets/js/status'

export default {
  name: 'applyOpenStore',
  components: {
    uploadImagesOne,
  },
  data () {
    return {
      endData: '结束时间',
      loading: false,
      PROJECT_NAME,
      // 容器滚动条
      showBackTop: false,
      scrollTop: 0,
      // 基础数据
      data: returnBaseDataStoreInfo(),
      // 表单
      formRules,
      formNameEditStore: 'formNameEditStore',
      companyTypeArray,
      taxpayerTypeArray,
      businessLicenseTypeArray,
      bankTypeArray,
      // 所在区域
      regionValue: [],
      showRegion: false,
      // 地图
      mapObj: null,
      mapInfoWin: null,
      defaultLat: 22.572369,
      defaultLng: 113.862587,
      // 银行卡
      bankCardFocus: false,
      userInfo: '',
      noPersonHoursEnd: false,
      noBusinessHoursEnd: false,
      bankLoading: false,
      bankList: [],
      bankBranchList: []
    }
  },
  created () {
    this.userInfo = JSON.parse(localStorage.getItem('user_info'))
    this.data.checkData.contactPerson = this.userInfo.contactPerson
    this.getStoreData().finally(() => {
      this.setRegionArray()
      this.$nextTick(() => {
        this.openMap()
      })
    })
  },
  methods: {
    pushTestData () {
      const reviewId = this.data.reviewId
      this.data = returnBaseDataStoreInfoTestData()
      this.data.reviewId = reviewId
      this.setRegionArray()
      this.$nextTick(() => {
        this.createCenter()
      })
    },
    againEdit () {
      this.data.reviewStatus = 0
      this.data.reviewId = ''
      this.setRegionArray()
      this.$nextTick(() => {
        this.openMap()
      })
    },
    /**
       * 容器滚动条
       */
    mainScrollEvent (e) {
      const homeMain = document.getElementById('supplierEditStoreInfo')
      const top = homeMain.scrollTop
      this.scrollTop = top
      if (top > 300) {
        this.showBackTop = true
      } else {
        this.showBackTop = false
      }
    },
    goBack,
    goHome,
    goBackTop (e) {
      const homeMain = document.getElementById('supplierEditStoreInfo')
      let top = homeMain.scrollTop
      const spaceNumber = 50
      const topSpace = top / spaceNumber
      const timeOut = setInterval(() => {
        top -= topSpace
        if (top < 0) top = 0
        homeMain.scrollTop = top
        if (top <= 0) clearInterval(timeOut)
      }, parseInt(200 / spaceNumber))
    },
    /**
       * 选择区域
       * */
    lazyLoad (node, resolve) {
      const levelMax = 4
      const data = {}
      if (node.level === 0) {
        data.parentId = 0
      } else {
        data.parentId = node.data.id
      }
      this.axios.get(URL.region.list, {
        params: data
      }).then(res => {
        if (node.level >= (levelMax - 1)) {
          res.data.forEach(item => {
            item.leaf = true
          })
        }
        resolve(res.data)
      }).catch(() => {
        resolve([])
      })
    },
    setRegionArray (info = this.data.checkData.storeJson) {
      if (this.data.reviewStatus > 1) return false
      if (typeof info === 'object') {
        if (this.showRegion) this.showRegion = false
        this.regionValue = [info.provinceCode, info.cityCode, info.areaCode, info.townCode]
        this.$nextTick(() => {
          this.showRegion = true
        })
      }
    },
    regionChange (node) {
      [this.data.checkData.storeJson.provinceCode, this.data.checkData.storeJson.cityCode, this.data.checkData.storeJson.areaCode, this.data.checkData.storeJson.townCode] = node
      this.data.checkData.storeJson.address = ''
      this.getRegionLatLng()
    },
    getRegionSelection () {
      const selection = this.$refs.region.getCheckedNodes()[0]
      let node = []; let labels = []
      if (selection) {
        node = selection.path
        labels = selection.pathLabels
      }
      return { node, labels, selection }
    },
    joinAddressInfo () {
      const address = this.data.checkData.storeJson.address || ''
      const addressInfo = this.getRegionSelection().labels.join(' ') + ' ' + address
      if (address) {
        this.data.checkData.storeJson.addressInfo = addressInfo
      } else {
        this.data.checkData.storeJson.addressInfo = ''
      }
      return addressInfo
    },
    /**
       * 地图 */
    // 初始化地图函数  自定义函数名init
    openMap () {
      if (this.mapObj) return false
      if (this.data.reviewStatus > 1) return false
      // 定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
      this.mapObj = new qq.maps.Map(document.getElementById('mapBox'), {
        zoom: 20
      })
      // qq.maps.event.addListener(this.mapObj, 'click', function(e) {
      //   let lat = e.latLng.lat.toFixed(6);
      //   let lng = e.latLng.lng.toFixed(6);
      // });
      this.mapInfoWin = new qq.maps.InfoWindow({
        map: this.mapObj
      })
      // this.createCenter()
    },
    // 点击选点
    mapEventClick (latitude, longitude) {

    },
    // 设置地图中心、文本框
    createCenter (latitude = this.data.checkData.storeJson.latitude, longitude = this.data.checkData.storeJson.longitude) {
      if (!latitude || !longitude) {
        latitude = this.defaultLat
        longitude = this.defaultLng
      }
      const center = new qq.maps.LatLng(latitude, longitude)
      this.mapObj.setCenter(center)// 坐标为天安门
      this.mapInfoWin.close()
      this.mapInfoWin.setContent('<div style="text-align:center;white-space:' +
        'nowrap;margin:10px;">经纬度: [' + latitude + ', ' +
        longitude + ']</div>')
      this.mapInfoWin.setPosition(center)
      this.mapInfoWin.open()
      return center
    },
    // 用户定位 - 选择省市区
    getRegionLatLng () {
      if (!this.data.checkData.storeJson.townCode) {
        return this.$message.error('请先选择区域')
      }
      this.getLatLng(this.joinAddressInfo())
    },
    // 用户定位 - 输入完地址
    getAddressLatLng () {
      if (!this.data.checkData.storeJson.townCode) {
        return this.$message.error('请先选择区域')
      }
      if (!this.data.checkData.storeJson.address) {
        return this.$message.error('请输入详细地址,然后重新点击定位')
      }
      this.getLatLng(this.joinAddressInfo())
    },
    getLatLng (address = '') {
      // 获取经纬度
      const url = 'https://apis.map.qq.com/ws/geocoder/v1/?key=ZN6BZ-SYPKU-JT3VF-BZUXO-K53ZE-5CFJE&address=' + address
      this.$jsonp(url, { output: 'jsonp' }).then(res => {
        const explainInfo = res.result
        this.data.checkData.storeJson.latitude = explainInfo.location.lat
        this.data.checkData.storeJson.longitude = explainInfo.location.lng
        this.createCenter(explainInfo.location.lat, explainInfo.location.lng)
      }).catch(() => {

      }).finally(() => {
        this.loading = false
      })
    },
    /**
       * 修改表单
       * */
    // 主体类型
    changeCompanyType (e) {
      const checkData = this.data.checkData
      const qualificationJson = checkData.qualificationJson
      const settlementJson = checkData.settlementJson
      let bankHolderName = ''
      if (e === 2) { // 公司
        if (settlementJson.bankType !== 'public') {
          settlementJson.bankType = 'public'
        }
        bankHolderName = qualificationJson.companyName || ''
        settlementJson.bankHolderName = bankHolderName
      } else { // 个人
        if (settlementJson.bankType === 'public') {
          bankHolderName = qualificationJson.companyName || ''
        } else if (settlementJson.bankType === 'private') {
          bankHolderName = qualificationJson.operatorsName || ''
        } else if (!settlementJson.bankHolderName) {
          settlementJson.bankHolderName = ''
        }
        settlementJson.bankHolderName = bankHolderName
      }
    },
    // 营业执照类型
    changebusinessLicenseType () {

    },
    // 结算类型
    changeBankType (e) {
      const checkData = this.data.checkData
      const qualificationJson = checkData.qualificationJson
      const settlementJson = checkData.settlementJson
      let bankHolderName = ''
      if (e === 'private') { // 私账
        bankHolderName = qualificationJson.operatorsName || ''
      } else { // 公账
        bankHolderName = qualificationJson.companyName || ''
      }
      settlementJson.bankHolderName = bankHolderName
    },
    // 主体名称
    blurContactPerson (e) {
      const checkData = this.data.checkData
      const qualificationJson = checkData.qualificationJson
      const settlementJson = checkData.settlementJson
      if (settlementJson.bankType === 'public') {
        if (qualificationJson.companyName) {
          settlementJson.bankHolderName = qualificationJson.companyName
        }
      }
    },
    // 法人姓名
    blurOperatorsName (e) {
      const checkData = this.data.checkData
      const qualificationJson = checkData.qualificationJson
      const settlementJson = checkData.settlementJson
      if (settlementJson.bankType === 'private') {
        if (qualificationJson.operatorsName) {
          settlementJson.bankHolderName = qualificationJson.operatorsName
        }
      }
    },
    /**
       * 提交前校验工作
       */
    submitBeforeCheckData (data = this.data) {
      const checkData = data.checkData
      if (checkData.settlementJson.bankType === 'public') {
        if (checkData.qualificationJson.companyName !== checkData.settlementJson.bankHolderName) {
          this.$message.error('账户类型为“公账”时，银行“开户名称”与“主体名称”须相同！')
          return false
        }
      } else {
        if (checkData.qualificationJson.operatorsName !== checkData.settlementJson.bankHolderName) {
          this.$message.error('账户类型为“对私”时，银行“开户名称”与“经营者姓名”须相同！')
          return false
        }
      }
      return true
    },
    /**
       * 获取、保存、提交
       */
    getStoreData () {
      const data = {

      }
      this.loading = true
      return this.axios.get(URL.supplierBasicMessage.getSupplierCheckData, { params: data }).then(res => {
        const newData = res.data
        if (typeof newData.checkData === 'object' && newData.checkData !== null) {
          this.data = newData
        }
        this.loading = false
        return Promise.resolve(res)
      }).catch(res => {
        this.$message.error('获取数据失败，请稍后再试!')
        this.loading = false
        return Promise.reject(res)
      })
    },
    saveReviewData () {
      const data = this.data
      if (data.checkData.qualificationJson.taxpayerType !== 2) {
        data.checkData.qualificationJson.normalTaxpayerImg = ''
      }
      if (data.checkData.settlementJson.bankType !== 'public') {
        data.checkData.settlementJson.openPermitImg = ''
      }
      this.loading = true
      return this.axios.post(URL.supplierBasicMessage.submitSupplierCheckData, data).then(res => {
        this.data.reviewStatus = 2
        this.loading = false
        return Promise.resolve(res)
      }).catch(res => {
        this.$message.error('提交审核失败，请稍后再试!' + res.msg || res.message || '')
        this.loading = false
        return Promise.reject(res)
      })
    },
    saveDraftData () {
      const data = this.data
      this.loading = true
      return this.axios.post(URL.supplierBasicMessage.saveSupplierCheckData, data).then(res => {
        if (res.data.reviewId) this.data.reviewId = res.data.reviewId
        this.loading = false
        return Promise.resolve(res)
      }).catch(res => {
        this.$message.error('保存草稿失败，请稍后再试!' + res.msg || res.message || '')
        this.loading = false
        return Promise.reject(res)
      })
    },
    /**
       * 表单提交及重置
       * @param formName
       */
    submitForm (formName = '') { // 按钮：提交审核
      if (typeof formName !== 'string') formName = this.formNameEditStore
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!')
          return false
        }
        if (!this.submitBeforeCheckData()) return false
        this.saveReviewData()
      })
    },
    submitFormDraft (formName = '') { // 按钮：保存草稿
      if (typeof formName !== 'string') formName = this.formNameEditStore
      this.saveDraftData()
    },
    resetForm (formName = '') {
      if (typeof formName !== 'string') formName = this.formNameEditStore
      this.$refs[formName].resetFields()
    },
    radioChangePerson(e) {
      if (e) {
        this.data.checkData.qualificationJson.personHoursEnd = "";
      }
      this.noPersonHoursEnd = e;
    },
    // 地址获取回调
    lazyLoad2(node, resolve) {
      this.lazyLoad3(node, resolve, 2)
    },
    // 地址获取回调
    lazyLoad3(node, resolve, levelMax = 3) {
      let data = {};
      if (node.level === 0) {
        data.parentId = 0;
      } else {
        data.parentId = node.data.id;
      }
      this.axios
        .get(URL.region.list, {
          params: data
        })
        .then(res => {
          if (node.level >= levelMax - 1)
            res.data.forEach(item => {
              item.leaf = true;
            });
          resolve(res.data);
        })
        .catch(() => {
          resolve([]);
        });
    },
    //选中最后一级城市触发
    regionYYChange(node) {
      console.log(node);
      let nodes = this.$refs.businessAddress.getCheckedNodes()[0]
      console.log(nodes);
      this.data.checkData.qualificationJson.businessProvince = nodes.pathLabels[0]
      this.data.checkData.qualificationJson.businessCity = nodes.pathLabels[1]
      this.data.checkData.qualificationJson.businessCounty = nodes.pathLabels[2]
      this.data.checkData.qualificationJson.businessProvinceId = nodes.path[0]
      this.data.checkData.qualificationJson.businessCityId = nodes.path[1]
      this.data.checkData.qualificationJson.businessCountyId = nodes.path[2]
    },
    // 营业期限变化
    dateChange(e) {
      this.radio = '';
      this.endData = '结束时间';
    },
    // 经营时间选择长期逻辑
    radioChange(e) {
      if(e){
        this.radio = '1';
        this.data.checkData.qualificationJson.businessHoursEnd = '';
        this.endData = '长期';
      }else {
        this.radio = '';
        this.dateChange();
      }
    },
    bankTypeChange() {
      this.data.checkData.settlementJson.bankCode = ''
      this.data.checkData.settlementJson.bankBranchCode = ''
      this.bankList = [];
      this.bankBranchList = [];
    },
    bankChange(value) {
      this.data.checkData.settlementJson.bankBranchCode = ''
      this.bankBranchList = [];
      this.bankList.forEach((item) => {
        if (item.id == value) {
          this.data.checkData.settlementJson.bankName = item.name
          return false
        }
      })
    },
    bankBranchChange(value) {
      this.bankBranchList.forEach((item) => {
        if (item.id == value) {
          this.data.checkData.settlementJson.bankBranch = item.name
          return false
        }
      })
    },
    getBankNumberList(name) {
      if (name !== '') {
        this.bankLoading = true
        this.axios.get(URL.adminSystemUser.getBankNumberList, {
          params: {
            name,
            status: this.data.checkData.settlementJson.bankType == 'public' ? 1 : 0
          }
        }).then(({data}) => {
          this.bankLoading = false
          this.bankList = data.records
        }).catch(() => {
          this.bankLoading = false
          this.bankList = [];
        })
      } else {
        this.bankList = [];
      }
    },
    getBankBranchNumberList(name) {
      if(!this.data.checkData.settlementJson.bankCode){
        return this.$message.error('请先选择开户行')
      }
      if (name !== '') {
        this.bankLoading = true
        this.axios.get(URL.adminSystemUser.getBankBranchNumberList, {
          params: {
            name,
            bankId: this.data.checkData.settlementJson.bankCode
          }
        }).then(({data}) => {
          this.bankLoading = false
          this.bankBranchList = data.records
        }).catch(() => {
          this.bankLoading = false
          this.bankBranchList = [];
        })
      } else {
        this.bankBranchList = [];
      }
    },
    //选中最后一级城市触发
    regionBankChange(node) {
      console.log(node);
      let nodes = this.$refs.bankAddress.getCheckedNodes()[0]
      console.log(nodes);
      this.data.checkData.settlementJson.bankProvince = nodes.pathLabels[0]
      this.data.checkData.settlementJson.bankCity = nodes.pathLabels[1]
      this.data.checkData.settlementJson.bankProvinceId = nodes.path[0]
      this.data.checkData.settlementJson.bankCityId = nodes.path[1]
    },
  },
  computed: {
    operatorName () {
      if (this.data.checkData.qualificationJson.companyType === 2) {
        return '法人'
      } else {
        return '经营者'
      }
    },
    bankCardNo () {
      const bankCard = this.data.checkData.settlementJson.bankCard
      if (!bankCard) return bankCard
      let bankCardNo = ''
      bankCardNo = split(bankCard.match(/\S/ig))
      function split (arr = []) {
        const len = 4
        let str = ''
        if (!arr.length) {
          str = ''
        } else if (arr.length <= len) {
          return arr.join('')
        } else {
          str = arr.splice(0, len).join('')
          str += '-'
          str += split(arr)
        }
        str = str.replace(/(^-)(-$)/, '')
        return str
      }
      return bankCardNo
    },
  }
}
</script>

<style scoped lang="less">
@import "../../../assets/css/data";
.edit-store-main-title {
  img {
    display: block;
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  .title {
    font-size: @font-size-main-heading;
  }
}
.edit-store-action {
  .el-button {
    width: 112px;
  }
  .edit-goods-action {
  }
}
.map-width {
  width: 450px;
}
.map-left-width {
  max-width: 700px;
}
.padding-right-map {
  padding-right: 450px + 20px;
}
.review-status-box {
  text-align: center;
  .el-icon {
    font-size: 200px;
  }
}
</style>
