export function returnBaseDataStoreInfo () {
  return {
    // 审核数据 ,
    checkData: returnBaseDataStoreInfoCheckData(),
    reviewId: undefined, // 审核编号：如果有的话 ,
    reviewRemark: '', // 审核拒绝理由：如果有的话 ,
    reviewStatus: 0, // 审核状态:0、未提交过数据 1、草稿箱(未提审)， 2、审核中，3、审核通过，4、审核拒绝
  }
}
// 审核数据
export function returnBaseDataStoreInfoCheckData () {
  return {
    // 联系人姓名 ,
    contactPerson: '',
    // 营业主体资料信息 ,
    qualificationJson: returnBaseDataStoreInfoQualificationJson(),
    // 结算银行信息 ,
    settlementJson: returnBaseDataStoreInfoSettlementJson(),
    // 店铺基本信息
    // "storeJson": returnBaseDataStoreInfoStoreJson(),
  }
}

// 营业主体资料信息
export function returnBaseDataStoreInfoQualificationJson () {
  return {
    businessLicenseImg: '', // 公司营业执照
    organizationImg:'',//	组织机构代码证
    businessLicenseType: 1, // 公司营业执照类型
    businessLicenseNo: '', // 营业执照号 ,
    circulationPermitImg: '', // 食品流通许可证 ,
    companyName: '', // 公司名称 ,
    shortName:'', //经营简称
    companyType: 1, // 公司类型:1-个体户 2-公司 ,
    normalTaxpayerImg: '', // 一般纳税人证明材料 ,
    operatorsCardBackImg: '', // 经营者/法人身份证背面 ,
    operatorsCardNo: '', // 经营者/法人身份证号 ,
    operatorsCardPositiveImg: '', // 经营者/法人身份证正面 ,
    operatorsEmail: '', // 经营者/法人邮箱 ,
    operatorsName: '', // 经营者/法人姓名 ,
    operatorsPhone: '', // 经营者/法人联系方式 ,
    taxpayerType: 1, // 纳税人类型:1-小规模纳税人 2-一般纳税人
    "businessProvince": "",
    "businessProvinceId": "",
    "businessCity": "",
    "businessCityId": "",
    "businessCounty": "",
    "businessCountyId": "",
    "personHoursStart": "",
    "personHoursEnd": "",
    registeredAddress: "",
    businessHoursStart: "",
    businessHoursEnd: "",

  }
}

// 结算银行信息
export function returnBaseDataStoreInfoSettlementJson () {
  return {
    bankBranch: '', // 开户银行支行 ,
    bankBranchCode: "",
    bankCard: '', // 银行卡号 ,
    bankHolderName: '', // 开户名 ,
    bankName: '', // 开户银行 ,
    bankCode: "",
    bankType: 'public', // 账户类型:public-公账 private-私账 ,
    openPermitImg: '', // 开户许可证 ,
    type: 15, // (只允许后台修改,注册时都是默认【15-半月结】)结算周期:-1预付结 0-现结 1-日结 7-周结 15-半月结 30-月结 10-确认收货结算
    bankCity: "",
    bankCityId: "",
    bankProvince: "",
    bankProvinceId: "",
  }
}

// 店铺基本信息
export function returnBaseDataStoreInfoStoreJson () {
  return {
    address: '', // 详细地址 ,
    addressInfo: '', // 完整的店铺所在地地址信息 ,
    areaCode: undefined, // 店铺所在地区域编码 ,
    brief: '', // 店铺简介
    cityCode: undefined, // 店铺所在地城市编码 ,
    latitude: '', // 纬度 ,
    logo: '', // 店铺logo ,
    longitude: '', // 经度 ,
    name: '', // 店铺名称 ,
    provinceCode: undefined, // 店铺所在地省份编码 ,
    townCode: undefined, // 店铺所在地街道编码
  }
}

export function returnBaseDataStoreInfoTestData () {
  return {
    checkData: {
      contactPerson: '陈某某',
      qualificationJson: {
        businessLicenseImg: 'http://img.lfnc18.com/fzgmall/images/20210727/4f1977509bc737f36239fe0496af3b30.jpg',
        businessLicenseNo: '54651351465435132',
        circulationPermitImg: 'http://img.lfnc18.com/fzgmall/images/20210727/4f1977509bc737f36239fe0496af3b30.jpg',
        companyName: '陈某某的公司',
        companyType: 1,
        normalTaxpayerImg: 'http://img.lfnc18.com/fzgmall/images/20210727/4f1977509bc737f36239fe0496af3b30.jpg',
        operatorsCardBackImg: 'http://img.lfnc18.com/fzgmall/images/20210727/4f1977509bc737f36239fe0496af3b30.jpg',
        operatorsCardNo: '123456789123456789',
        operatorsCardPositiveImg: 'http://img.lfnc18.com/fzgmall/images/20210727/4f1977509bc737f36239fe0496af3b30.jpg',
        operatorsEmail: '',
        operatorsName: '陈xx',
        operatorsPhone: '13512341234',
        taxpayerType: 2
      },
      settlementJson: {
        bankBranch: '保安哈哈支行',
        bankCard: '1234567890123456',
        bankHolderName: '陈某某的公司',
        bankName: '哈哈银行',
        bankType: 'public',
        openPermitImg: 'http://img.lfnc18.com/fzgmall/images/20210727/4f1977509bc737f36239fe0496af3b30.jpg',
        type: 15
      },
      storeJson: {
        address: '城东街道',
        addressInfo: '山西省 阳泉市 郊区 义井镇 城东街道',
        areaCode: '140311',
        brief: '陈某的店铺不卖东西只收钱',
        cityCode: '1403',
        latitude: 37.845798,
        logo: 'http://img.lfnc18.com/fzgmall/images/20210727/4f1977509bc737f36239fe0496af3b30.jpg',
        longitude: 113.604218,
        name: '陈某某的店铺',
        provinceCode: '14',
        townCode: '140311102'
      },
    },
    reviewId: undefined,
    reviewRemark: '',
    reviewStatus: 0,
  }
}
